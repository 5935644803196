<template>
  <div class="ui grid container-6" id="prtnrwus">
    <div class="partner">
      <div class="border-violet"></div>
      <div class="border-shadow"></div>
      <div class="border-yellow"></div>

      <div class="two column row stackable">
        <div class="column div-1">
          <div class="left">
            <h1>Partner<span> With Us</span></h1>

            <p>Be part of the TayoCash community and earn extra income for you and your business when you become an accredited partner or merchant. Click below to learn how.</p>

            <a class="yellow" href="/partner">BECOME A PARTNER</a>
          </div>
        </div>
        <div class="column div-2">
          <div class="right">
            <div class="partners ui two column row">
              <div class="column">
                <div class="item">
                  <img class="ub" src="@/assets/p1.svg">
                </div>
                <div class="item">
                  <img class="bux" src="@/assets/p2.svg">
                </div>
                <div class="item">
                  <img class="globe" src="@/assets/p3.svg">
                </div>
                <div class="item">
                  <img class="ecpy" src="@/assets/p4.svg">
                </div>
              </div>
              <div class="column">
                <div class="item">
                  <img class="ld1" src="@/assets/p5.svg">
                  </div>
                <div class="item">
                  <img class="npi" src="@/assets/p6.svg">
                </div>
                <div class="item">
                  <img class="j6w" src="@/assets/p7.svg">
                </div>
                <div class="item">
                  <img class="ldcntrl" src="@/assets/p8.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content6'
}
</script>

<style scoped lang='scss'></style>
